import React from 'react';
import { VStack } from '@chakra-ui/react';

export default function Layout({ children }) {
  return (
    <VStack
      minHeight="100vh"
      justify="space-between"
      align="stretch"
      spacing={0}
    >
      {children}
    </VStack>
  );
}
