import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

const baseStyle = defineStyle({
  fontWeight: '600',
  fontFamily: "'Marianne', Arial, sans-serif",
  lineHeight: { base: '1',  md: '1.2' },
  color: "brand.100",
});

const hero = defineStyle({
  fontSize: [ '2.5rem', '2.5rem', '2.5rem', '2.75rem' ],
  letterSpacing: "-.125px",
  my: [ "1em", "1em", "2rem" ],
});

const results = defineStyle({
  fontSize: [ '1.5rem', '1.5rem', '2rem', '2rem' ],
  mb: "1em",
});

const h1 = defineStyle({
  fontSize: [ '2rem', '2rem', '2.25rem', '2.75rem' ],
});

const h2 = defineStyle({
  fontSize: [ '1.75rem', '1.75rem', '2rem', '2.25rem' ],
});

const h3 = defineStyle({
  fontSize: [ '1rem', '1rem', '1.25rem', '1.25rem' ],
});

const h3bis = defineStyle({
  fontSize: '1.25rem',
});

const h4 = defineStyle({
  fontSize: '1rem',
});

export const headingTheme = defineStyleConfig({
  baseStyle,
  variants: {
    "hero": hero,
    "results": results,
    "h1": h1,
    "h2": h2,
    "h3": h3,
    "h3bis": h3bis,
    "h4": h4,
  },
})