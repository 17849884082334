import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  field: {
    backgroundColor: 'white',
    borderRadius: 'full',
    color: 'black',
    borderColor: 'black.50',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    outlineOffset: '-1px !important',
    _focus: {
      outlineColor: 'brand.100',
    },
    _invalid: {
      borderColor: 'danger.100',
      color: 'danger.100',
    },
  },
});

const danger = defineStyle({
  field: { borderColor: 'danger.100' },
});

export const inputTheme = defineStyleConfig({
  baseStyle,
  variants: {
    danger,
  },
  defaultProps: {
    variant: null,
  },
});
