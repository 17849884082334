import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const outline = definePartsStyle({
  field: {
    backgroundColor: 'white',
    cursor: 'pointer',
    color: 'black',
    width: '100%',
    border: '1px solid',
    borderColor: 'black.50',
    borderRadius: 'full',
    outlineOffset: '-1px !important',
    _focus: {
      outlineColor: 'brand.100',
    },
    _hover: {
      borderColor: 'black.50',
    },
    _invalid: {
      borderColor: 'danger.100',
      color: 'danger.100',
    },
  },
  icon: {
    color: 'cumulus.100',
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: { outline },
});
