import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './app/components/refonte2024/presentational/Button';
import { badgeTheme } from './app/components/refonte2024/presentational/Badge';
import { inputTheme } from './app/components/refonte2024/presentational/Input';
import { selectTheme } from './app/components/refonte2024/presentational/Select';
import { tabsTheme } from './app/components/refonte2024/presentational/Tabs';
import { tagTheme } from './app/components/refonte2024/presentational/Tag';
import { textareaTheme } from './app/components/refonte2024/presentational/Textarea';
import { headingTheme } from './app/components/refonte2024/presentational/Heading';
import { accordionTheme } from './app/components/refonte2024/presentational/Accordion';
import { switchTheme } from './app/components/refonte2024/presentational/Switch';

const theme = extendTheme({
  cssVarPrefix: 'ifprofs',
  uiWidth: '80rem',
  maxFieldsWidth: '30rem',
  fonts: {
    heading: '"Marianne", Arial, sans-serif',
    body: '"Marianne", Arial, sans-serif',
  },
  breakpoints: {
    base: '0em', // 0px
    sm: '48em', // portrait tablets
    md: '64em', // landing tablets
    lg: '75em', // desktop 1200px
    xl: '90em', // desktop 1440px
    '2xl': '100em', // desktop 1600px
  },
  colors: {
    brand: {
      100: '#3558A2',
      50: '#9AABD0',
      25: '#CDD5E8',
      10: '#EBEEF6',
    },
    black: {
      100: '#000000',
      75: '#3F3F3F',
      50: '#808080',
      25: '#bfbfbf',
      10: '#e5e5e5',
    },
    cumulus: {
      100: '#7ab1e8',
      50: '#bcd8f3',
      25: '#deebf9',
      10: '#f2f7fd',
    },
    tournesol: {
      100: '#ffe552',
      50: '#fff2a8',
      25: '#fff8d4',
      10: '#fffcee',
    },
    menthe: {
      100: '#21AB88',
      50: '#90D5C3',
      25: '#C7EAE1',
      10: '#E9F7F3',
    },
    green: {
      50: '#E9F7F3',
      100: '#C7EAE1',
      200: '#C7EAE1',
      300: '#90D5C3',
      400: '#90D5C3',
      500: '#21AB88', // Couleur principale. Utilisée pour matcher avec "menthe" quand utilisé avec les colorScheme
      600: '#2C7A7B',
      700: '#285E61',
      800: '#234E52',
      900: '#1D4044',
    },
    bourgeon: {
      100: '#99c221',
      50: '#cce090',
      25: '#e5f0c7',
      10: '#f5f9e9',
    },
    tuile: {
      100: '#ff9575',
      50: '#ffcaba',
      25: '#ffe4dd',
      10: '#fff4f1',
    },
    gray: {
      100: '#718096',
      50: '#e2e8f0',
      25: '#edf2f7',
      10: '#f7fafc',
    },
    danger: {
      100: '#cc1111',
      50: '#da8c8a',
      25: '#ebc6c5',
      10: '#f7eae9',
    },
    success: {
      100: '#2C7A7B', // = green.600 pour être un tout petit peu plus foncé que menthe.100 afin de pouvoir écrire en blanc par dessus sans soucis RGAA
      50: '#68d391',
      25: '#9ae6b4',
      10: '#c6f6d5',
    },
    ifprofsWhite: {
      100: '#ffffff',
    },
  },
  components: {
    Button: buttonTheme,
    Badge: badgeTheme,
    Input: inputTheme,
    Select: selectTheme,
    Tabs: tabsTheme,
    Tag: tagTheme,
    Textarea: textareaTheme,
    Heading: headingTheme,
    Accordion: accordionTheme,
    Form: { baseStyle: { requiredIndicator: { color: 'danger.100' } } },
    Switch: switchTheme,
  },
});

export default theme;
