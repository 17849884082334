import { Box, useTheme } from '@chakra-ui/react';

export default function UIWidth({
  mx = null,
  heightForMD = null,
  basePx = null,
  px = null,
  children,
  backgroundColor = null,
  borderRadius = null,
  isHalfForMd = null,
}) {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      maxWidth={{
        base: theme.uiWidth,
        md: isHalfForMd ? "calc(" + theme.uiWidth + " / 2)" : null
      }}
      mx={mx ? mx : "auto"}
      px={{ base: basePx ? basePx : "1rem", md: px ? px : "2rem" }}
      height={{ md: heightForMD }}
      backgroundColor={backgroundColor ? backgroundColor : null}
      borderRadius={borderRadius ? borderRadius : null}
      className="UIWidth"
    >
      {children}
    </Box>
  );
}
