import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'white',
  },
  track: {
    bg: 'black.25',
    _checked: {
      bg: 'brand.100',
    },
  },
});

const ifprofs = definePartsStyle({
  container: {
    mt: '.125rem',
  },
  track: {
    bg: 'red.500',
    _checked: {
      bg: 'green.500',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { ifprofs },
});
