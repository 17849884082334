import { stringify } from 'qs';

export interface UrlPagination {
  page?: number;
  itemsPerPage?: number;
  countTotalResults?: boolean;
}

export type ResponseAttributes = {
  relationIncludes?: Array<string>;
  fields?: Record<string, string>;
};

export type SearchAttributes = {
  filters?: Record<string, string>;
  orders?: Array<string>;
  pagination?: UrlPagination;
};

export type Search = SearchAttributes & ResponseAttributes;

export function buildUrlFromSearch(baseUrl: string, search: Search): string {
  const { relationIncludes, filters, orders, pagination, fields } = search;
  return buildSearchUrl(
    baseUrl,
    fields,
    relationIncludes,
    filters,
    orders,
    pagination
  );
}

export function buildSearchUrl(
  baseUrl: string,
  fields: Record<string, string> = {},
  relationIncludes: Array<string> = [],
  filters: Record<string, string> = {},
  orders: Array<string> = [],
  pagination: UrlPagination = {}
): string {
  const joinedRelationIncludes = relationIncludes.join(',');
  const joinedOrders = orders.join(',');
  const resolvedPagination = {
    ...(pagination.itemsPerPage !== undefined && {
      items: pagination.itemsPerPage,
    }),
    ...(pagination.countTotalResults && { countTotalResults: '1' }),
    ...(pagination.page && { page: pagination.page }),
  };

  const resolvedFields = Object.entries(fields).reduce((acc, [key, value]) => {
    if (value === null || value === '') {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});

  // apiClient encode url, so we need to disable it
  return `${baseUrl}?${stringify(
    {
      filters,
      fields: resolvedFields,
      ...(orders.length && { order: joinedOrders }),
      ...(relationIncludes.length > 0 && { includes: joinedRelationIncludes }),
      ...resolvedPagination,
    },
    { encode: false }
  )}`;
}
