import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    position: 'relative',
    whiteSpace: 'nowrap',
    _selected: {
      fontWeight: '600',
      color: 'brand.100',
    },
    _focusVisible: {
      boxShadow: 'none',
      outline: '2px solid',
      outlineColor: 'brand.100',
      outlineOffset: '-2px',
    }
  },
  tablist: {
    mb: '0',
    position: 'relative',
    overflowX: 'auto',
    borderBottomWidth: '4px',
  },
  tabpanel: {
    padding: '1rem 0 0 0',
  },
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
