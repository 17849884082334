import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    borderTop: 'none',
    borderBottom: 'none',
  },
  container: {
    borderColor: 'black.25',
  },
  icon: {
    color: 'brand.100',
  },
});

const custom = definePartsStyle({
  root: {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid 1px',
    borderBottomColor: 'black.10',
  },
  container: {
    border: 'none',
  },
  button: {
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: 'solid 1px',
    borderTopColor: 'black.10',
    pl: 0,
    color: '#555',
    fontWeight: 600,
    _hover: {
      bg: 'transparent',
      color: 'brand.100',
    },
    _expanded: {
      color: 'brand.100',
      fontWeight: 600,
    },
  },
  panel: {
    padding: '1rem',
    backgroundColor: 'brand.10',
  },
  icon: {
    boxSize: '1.5rem',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { custom },
});
