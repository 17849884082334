import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  backgroundColor: 'black.10',
  fontWeight: '400',
  borderRadius: '0.3rem',
  px: '0.5rem',
});

const brand100 = defineStyle({
  backgroundColor: 'brand.100',
  color: 'white',
});
const brand50 = defineStyle({
  backgroundColor: 'brand.50',
  color: 'white',
});
const brand25 = defineStyle({
  backgroundColor: 'brand.25',
  color: 'black',
});

const brandOutline = defineStyle({
  backgroundColor: 'white',
  color: 'brand.100',
  border: 'solid 1px',
  borderColor: 'brand.100',
});

const menthe100 = defineStyle({
  backgroundColor: 'menthe.100',
  color: 'black',
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  variants: {
    brand100,
    brand50,
    brand25,
    brandOutline,
    menthe100,
  }
});
