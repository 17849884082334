import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    border: '1px solid',
    padding: '0.5rem 1rem',
    background: 'black.10',
    borderColor: 'black.100',
  },
});

const community = definePartsStyle({
  container: {
    transition: 'background 0.25s ease-in-out',
    background: 'white',
    borderColor: 'tournesol.100',
    _hover: { background: 'tournesol.100' },
  },
});

const neutral = definePartsStyle({
  container: {
    borderColor: 'black.10',
  },
});

const sm = definePartsStyle({
  container: {
    padding: '.125em .5em',
    fontSize: '1em',
  }
});

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { community, neutral },
  sizes: {
    sm,
  }
});
