import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  outlineWidth: '2px',
  outlineOffset: '-2px !important',
  backgroundColor: 'white',
  borderColor: 'black.50',
  _focus: {
    outlineColor: 'brand.100',
  },
  _focusVisible: {
    boxShadow: 'none',
  },
  _hover: {
    borderColor: 'black.50',
  },
  _invalid: {
    borderColor: 'danger.100',
    color: 'danger.100',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { outline },
});
